
.container.contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option {
    background: var(--bg-variant-color);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact__option:hover {
    background: transparent;
    border-color: var(--primary-variant-color);
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}


/* *************************** FORM *************************** */

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--primary-variant-color);
    resize: none;
    color: var(--white);
}


/* *************** MEDIA QUERIES (MD) *************** */

@media screen and (max-width: 1024px) {
    .container.contact__container {
        grid-template-columns: 1fr;
        -ms-grid-template-columns: 1fr;
        gap: 2rem;
    }
  }
  
  
  /* *************** MEDIA QUERIES (SM) *************** */
  
@media screen and (max-width: 600px) {
    .container.contact__container {
        width: var(--container-width-sm);
    }
}