
header {
    height:100vh;
    padding-top: 6rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

.degrees{
    font-size: 0.8rem;
}

/* *********************** CTA *********************** */

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}


/* *********************** SOCIALS *********************** */

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--primary-color);
}

/* *********************** ME *********************** */

.nani {
    background: linear-gradient(var(--primary-color), transparent);
    width: 18rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 9rem);
    margin-top: 2rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 3rem 1.5rem 1.5rem 1.5rem;
}

/* *********************** SCROLL DOWN *********************** */

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 200;
    font-size: 0.9rem;
}


/* *************** MEDIA QUERIES (MD) *************** */

@media screen and (max-width: 1024px) {
    header {
        height: 100vh;
    }
  }
  
  
  /* *************** MEDIA QUERIES (SM) *************** */
  
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .header__socials, .scroll__down {
        display: none;
    }

}
  