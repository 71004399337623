
.services__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    -ms-grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
}

.service {
    background: var(--bg-variant-color);
    border-radius: 0 0 2rem 2rem;
    border: 1px solid var(--primary-color);
    height: fit-content;
}

.service:hover {
    background: transparent;
    border-color: var(--primary-variant-color);
    cursor: default;
}

.service__head {
    background:var(--primary-color);
    padding: 2rem;
    border-radius: 0 0 2rem 2rem;
    box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.service__head h3 {
    color: var(--bg-color);
    font-size: 1rem;
    text-align: center;
}

.service__list {
    padding: 2rem;
}

.service__list li {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
}

.service__list-icon {
    color: var(--primary-color);
    margin-top: 2px;
}

.service__list p {
    font-size: 0.9rem;
}


/* *************** MEDIA QUERIES (MD) *************** */

@media screen and (max-width: 1024px) {
    .services__container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    .service {
        height: auto;
    }
    
  }
  
  
  /* *************** MEDIA QUERIES (SM) *************** */
  
@media screen and (max-width: 600px) {
    .services__container {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
}