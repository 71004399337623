@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700&display=swap');

* {
  font-family: 'Poppins', 'Segoe UI', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --bg-color: #1F1F38;
  --bg-variant-color: #2C2C6C;
  --primary-color: #4DB5FF;
  --primary-variant-color: rgba(77, 181, 255, 0.4);
  --white: #FFFFFF;
  --light-color: rgba(255, 255, 255, 0.6);

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;

  --transition: all 400ms ease;
  --bg-textures: url('../src/assets/bg-texture.png');
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  background: var(--bg-color);
  color: var(--white);
  line-height: 1.7;
  background-image: var(--bg-textures);
}


/* *************** GENERAL STYLES *************** */

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1, h2, h3, h4, h5 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

section {
  margin-top: 8rem;
}

section > h2, section > h5 {
  color: var(--light-color);
  text-align: center;

}

section > h2 {
  color: var(--primary-color);
  margin-bottom: 3rem;
}

.light-text {
  color: var(--light-color);
}

a {
  color: var(--primary-color);
  transition: var(--transition);
}

a:hover {
  color: var(--white);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--primary-color);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--primary-color);
  transition: var(--transition);
}

.btn:hover {
  background: var(--white);
  color: var(--bg-color);
  border-color: transparent;
}

.btn-primary {
  background: var(--primary-color);
  color:var(--bg-color);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/* *************** MEDIA QUERIES (MD) *************** */

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 6rem;
  }
}


/* *************** MEDIA QUERIES (SM) *************** */

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  section > h2{
    margin-bottom: 2rem;
  }
}
